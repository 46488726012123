<template>
  <div class="bulk--repost">
    <div class="bulk--repost-desc">{{ $t('listing.bulkRepost.desc') }}</div>
    <div class="bulk--repost-select">
      <multiselect
        id="selectedListing"
        :options="listings"
        :close-on-select="false"
        track-by="uuid"
        label="name"
        select-label=""
        selected-label=""
        deselect-label=""
        :multiple="true"
        :internal-search="false"
        :loading="loading"
        :placeholder="$t('listing.bulkRepost.plc')"
        style="max-width: 300px"
        class="bulk--repost-input"
        @search-change="debounceGetListings"
        v-model="selectedListings"
      >
        <template slot="selection">
          <div v-show="selectedListings.length > 0">
            {{ `${selectedListings.length} ${$t('listing.bulkRepost.selected')}` }}
          </div>
        </template>
        <template slot="option" slot-scope="{ option }">
          <div class="bulk--repost-option">
            <div class="bulk--repost-img">
              <img :src="option.primary_photo" :alt="option.title" />
            </div>
            <div style="width: 100%; margin-right: 8px">
              <div class="bulk--repost-title">
                {{ option.title }}
              </div>
              <div class="bulk--repost-listing-id">
                {{ `${$t('general.listingNumber')}: ${option.listing_number}` }}
              </div>
              <div class="bulk--repost-price">
                {{ option.price_str }}
              </div>
            </div>
            <div>
              <div class="label--container" style="margin-bottom: 0">
                <div class="label">{{ option.property_type }}</div>
              </div>
            </div>
          </div>
        </template>
      </multiselect>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import HelperMixin from '@/mixins/helpers';
import LodashMixin from '@/mixins/lodash';
import { mapState } from 'vuex';

require('vue-multiselect/dist/vue-multiselect.min.css');

export default {
  name: 'bulk-repost-search',
  mixins: [HelperMixin, LodashMixin],
  components: { Multiselect },
  props: [],
  data: () => ({
    debounceGetListings: null,
  }),
  computed: {
    ...mapState({
      loading: (state) => state.listing.loading,
      listings: (state) => state.listing.listings,
    }),
    selectedListings: {
      get() {
        return this.$store.state.listing.selectedListings;
      },
      set(value) {
        this.$store.commit('listing/SET_SELECTED_LISTINGS', value);
      },
    },
  },
  created() {
    this.debounceGetListings = this.debounce(this.getListings, 500);
  },
  mounted() {
    this.getListings(null);
    this.$store.commit('listing/CLEAR_SELECTED_LISTINGS');
  },
  methods: {
    async getListings(query) {
      this.$store.commit('listing/SET_SEARCH_QUERY', query);
      await this.$store.dispatch('listing/getAllListings', {
        page: 1,
        useLoading: false,
        forBulkRepost: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';

.bulk--repost {
  .bulk--repost-desc {
    text-align: center;
    margin-bottom: 12px;
    font-size: $font-ml;
    @media #{$phones} {
      font-size: $font-md;
    }
  }

  .bulk--repost-select {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
    .bulk--repost-input {
      width: 500px;
      max-width: 500px !important;
    }
  }

  .bulk--repost-option {
    display: flex;
    align-items: center;
    .bulk--repost-img {
      img {
        width: 80px;
        height: 80px;
        object-fit: contain;
        margin-right: 8px;
        @media #{$phones} {
          width: 60px;
          height: 60px;
        }
      }
    }
    .bulk--repost-title {
      font-family: Poppins-SemiBold;
      font-size: $font-ml;
      color: $color-text-black;
      margin-bottom: 6px;
      // wrap is the correct value, ignore the warning
      //noinspection CssInvalidPropertyValue
      text-wrap: wrap;
      @media #{$phones} {
        font-size: $font-sm;
      }
    }
    .bulk--repost-listing-id {
      color: $color-text-gray-2;
      margin-bottom: 6px;
      @media #{$phones} {
        font-size: $font-xs;
      }
    }
    .bulk--repost-price {
      color: $color-text-black;
      @media #{$phones} {
        font-size: $font-xs;
      }
    }
  }
}
</style>
