<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <div class="container extra--padding mb-4">
      <page-loading :show="loading" />
      <bulk-repost-search></bulk-repost-search>
      <bulk-repost-listings></bulk-repost-listings>
      <bulk-repost-summary></bulk-repost-summary>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import DefaultHeadMixin from '@/mixins/defaultHead';
import BulkRepostSearch from '@/components/bulk-repost/search';
import BulkRepostListings from '@/components/bulk-repost/listings';
import BulkRepostSummary from '@/components/bulk-repost/summary';
import { mapState } from 'vuex';
import PageLoading from '@/components/content-loading/page-loading.vue';

export default {
  name: 'bulk-repost',
  mixins: [DefaultHeadMixin],
  components: {
    PageLoading,
    DefaultLayout,
    BulkRepostSearch,
    BulkRepostListings,
    BulkRepostSummary,
  },
  computed: {
    ...mapState({
      loading: (state) => state.listing.loading,
    }),
    breadcrumbs() {
      const currentName = this.$t('home.sidebarMenu.bulkRepost');
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: currentName,
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  methods: {},
  async mounted() {},
};
</script>

<style lang="scss" scoped></style>
