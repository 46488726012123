<template>
  <div class="bulk--repost-summary" v-if="selectedListings.length > 0">
    <div class="bulk--repost-desc">
      <span>{{ $t('listing.bulkRepost.summary1') }}</span>
      <span class="bulk--repost-amount"> {{ selectedListings.length }} </span>
      <span>{{ $t('listing.bulkRepost.summary2') }}</span>
    </div>
    <div class="bulk--repost-action">
      <div class="btn btn-primary" @click="tryBulkRepost">
        <img src="@/assets/img/icons/repost.png" />
        <span>{{ $t('general.btn.bulkRepost') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import HelperMixin from '@/mixins/helpers';
import { mapState } from 'vuex';

export default {
  name: 'bulk-repost-search',
  mixins: [HelperMixin],
  components: {},
  props: [],
  data: () => ({}),
  computed: {
    ...mapState({
      loading: (state) => state.listing.loading,
      selectedListings: (state) => state.listing.selectedListings,
    }),
  },
  mounted() {},
  methods: {
    tryBulkRepost() {
      this.$modal.show('bulk-repost-listing-modal', {
        submitAction: this.bulkRepost,
      });
    },
    async bulkRepost() {
      console.log('AYO BULK REPOST!');
      try {
        const success = await this.$store.dispatch(
          'listing/bulkRepost',
          this.selectedListings.map(function (listing) {
            return listing.uuid;
          }),
        );
        if (success) {
          await this.$swal(
            this.$i18n.t('general.success'),
            this.$i18n.t('listing.modal.bulkRepost.success'),
            'success',
          );
          this.$store.commit('listing/CLEAR_SELECTED_LISTINGS');
          await this.$router.push({
            path: `/`,
          });
        }
      } catch (e) {
        console.log('ERROR BULK REPOST: ', e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';

.bulk--repost-summary {
  margin-top: 36px;
  .bulk--repost-desc {
    text-align: center;
    margin-bottom: 12px;
    font-size: $font-ml;
    @media #{$phones} {
      font-size: $font-md;
    }
  }
  .bulk--repost-amount {
    font-family: Poppins-SemiBold;
  }
  .bulk--repost-action {
    display: flex;
    justify-content: center;
    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
      margin-right: 4px;
    }
  }
}
</style>
